import request from '@/utils/request'

export function getAfterReceipts(params) {
  return request({
    method: 'get',
    url: 'after-receipt',
    params
  })
}

export function handleAfterReceipt(id, data) {
  return request({
    method: 'post',
    url: `after-receipt/${id}`,
    data
  })
}

export function getAfterReceiptRecords() {
  return request({
    method: 'get',
    url: `after-receipt/record`
  })
}
