<template>
  <div class="accept-goods-container">
    <van-cell v-if="goods.status === 1" title="处理人" :value="goods.employee_name" />
    <van-cell v-if="goods.status === 1" title="处理时间" :value="goods.handle_at" />
    <van-cell
      v-if="goods.status === 1"
      title="处理结果"
      :value="goods.handle_type === 1 ? '可退' : '不可退'"
    >
      <!-- <template v-if="goods.handle_type === 2" #label>
        已收款：
        <span v-if="goods.cash > 0">现金{{ goods.cash }}元；</span>
        <span v-if="goods.wechat > 0">微信{{ goods.wechat }}元；</span>
        <span v-if="goods.trans > 0">转账{{ goods.trans }}元；</span>
      </template> -->
    </van-cell>
    <van-cell title="客户名称" :value="goods.after_goods.after_order.client.name" />
    <van-cell title="验收人" :value="goods.after_goods.after_order.accept.name" />
    <van-cell title="收条时间" :value="goods.created_at" />
    <div class="accept-goods-container__content">
      <div class="accept-goods-container__content-img">
        <van-image width="2.26666rem" height="2.26666rem" :src="goods.image" />
      </div>
      <div class="accept-goods-container__content-text">
        <div class="accept-goods-container__content-name">{{ goods.name }}</div>
        <div class="accept-goods-container__size-storage">
          <span>规格：1*{{ goods.size }}</span>
        </div>
        <div class="accept-goods-container__large-least">
          <div v-if="goods.good_volume > 0">
            完好的：
            <van-tag type="success">
              {{ goods.good_volume }}{{ goods.least_unit }}
            </van-tag>
          </div>
          <div v-if="goods.bad_volume > 0">
            损坏的：
            <van-tag type="danger">
              {{ goods.bad_volume }}{{ goods.least_unit }}
            </van-tag>
          </div>
        </div>
      </div>
      <div class="amount">
        ￥{{ goods.amount }}
      </div>
    </div>
    <van-button v-if="goods.status === 0" block square size="small" type="info" @click="showHandleActions(goods)">确认处理</van-button>
    <van-action-sheet v-model="showActions" :actions="actions" @select="handleSelected" />

  </div>
</template>

<script>
import { handleAfterReceipt } from '@/api/after-receipt'
export default {
  props: {
    goods: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showActions: false,
      actions: [
        { name: '可退', value: 1 },
        { name: '不可退', value: 2 }
      ],
      afterReceipt: null
    }
  },
  methods: {
    showHandleActions(afterReceipt) {
      this.afterReceipt = afterReceipt
      this.showActions = true
    },
    handleSelected(action) {
      if (action.value === 1) {
        this.confirm('确定商品可退吗？', '提示').then(() => {
          this.sendHandleRequest(action.value)
        })
      } else {
        this.confirm('确定商品不可退吗？确定后将进入退货差价流程', '提示').then(() => {
          this.sendHandleRequest(action.value)
        })
      }
      this.showActions = false
    },
    sendHandleRequest(handleType) {
      const data = {
        handle_type: handleType
      }

      this.beginLoad()
      handleAfterReceipt(this.afterReceipt.id, data).then(res => {
        this.success(res.msg)
        this.$emit('reload-data')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/ellipsis";
@import "src/styles/word-break";
@import "src/styles/color";
.accept-goods-container {
  overflow: hidden;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(48, 48, 48, 0.07);
  &__content {
    position: relative;
    display: flex;
    padding: 15px;
  }
  &__large-least {
    color: #a3a3a3;
    font-size: 13px;
  }
  &__content-text {
    @extend %ellipsis;
    padding: 0 10px;
  }
  &__content-name {
    @extend %word-break;
    color: #1c1c1c;
  }
  &__size-storage {
    color: #a3a3a3;
    font-size: 13px;
  }
  &__accept-info {
    padding: 0 15px 15px 15px;
  }
  &__accept-button {
    text-align: right;
  }
  &__finished-logo {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__tag {
    margin-left: 10px;
  }
}
.amount {
  color: $amountRedColor;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.radio {
  display: flex;
  justify-content: flex-end;
}
</style>
