<template>
  <div>
    <my-nav-bar
      title="处理记录"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list v-model="loading" class="list" :finished="finished" @load="getList">
        <after-receipt-goods v-for="goods in list" :key="goods.id" :goods="goods" />
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import AfterReceiptGoods from './components/goods'
import { getAfterReceiptRecords } from '@/api/after-receipt'
export default {
  components: { myNavBar, AfterReceiptGoods },
  data() {
    return {
      list: [],
      listQuery: {
        page: 0,
        limit: 10
      },
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getAfterReceiptRecords(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 15px 15px 5px 15px;
  }
</style>
