var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accept-goods-container"
  }, [_vm.goods.status === 1 ? _c('van-cell', {
    attrs: {
      "title": "处理人",
      "value": _vm.goods.employee_name
    }
  }) : _vm._e(), _vm.goods.status === 1 ? _c('van-cell', {
    attrs: {
      "title": "处理时间",
      "value": _vm.goods.handle_at
    }
  }) : _vm._e(), _vm.goods.status === 1 ? _c('van-cell', {
    attrs: {
      "title": "处理结果",
      "value": _vm.goods.handle_type === 1 ? '可退' : '不可退'
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "客户名称",
      "value": _vm.goods.after_goods.after_order.client.name
    }
  }), _c('van-cell', {
    attrs: {
      "title": "验收人",
      "value": _vm.goods.after_goods.after_order.accept.name
    }
  }), _c('van-cell', {
    attrs: {
      "title": "收条时间",
      "value": _vm.goods.created_at
    }
  }), _c('div', {
    staticClass: "accept-goods-container__content"
  }, [_c('div', {
    staticClass: "accept-goods-container__content-img"
  }, [_c('van-image', {
    attrs: {
      "width": "2.26666rem",
      "height": "2.26666rem",
      "src": _vm.goods.image
    }
  })], 1), _c('div', {
    staticClass: "accept-goods-container__content-text"
  }, [_c('div', {
    staticClass: "accept-goods-container__content-name"
  }, [_vm._v(_vm._s(_vm.goods.name))]), _c('div', {
    staticClass: "accept-goods-container__size-storage"
  }, [_c('span', [_vm._v("规格：1*" + _vm._s(_vm.goods.size))])]), _c('div', {
    staticClass: "accept-goods-container__large-least"
  }, [_vm.goods.good_volume > 0 ? _c('div', [_vm._v(" 完好的： "), _c('van-tag', {
    attrs: {
      "type": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.goods.good_volume) + _vm._s(_vm.goods.least_unit) + " ")])], 1) : _vm._e(), _vm.goods.bad_volume > 0 ? _c('div', [_vm._v(" 损坏的： "), _c('van-tag', {
    attrs: {
      "type": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.goods.bad_volume) + _vm._s(_vm.goods.least_unit) + " ")])], 1) : _vm._e()])]), _c('div', {
    staticClass: "amount"
  }, [_vm._v(" ￥" + _vm._s(_vm.goods.amount) + " ")])]), _vm.goods.status === 0 ? _c('van-button', {
    attrs: {
      "block": "",
      "square": "",
      "size": "small",
      "type": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.showHandleActions(_vm.goods);
      }
    }
  }, [_vm._v("确认处理")]) : _vm._e(), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.handleSelected
    },
    model: {
      value: _vm.showActions,
      callback: function callback($$v) {
        _vm.showActions = $$v;
      },
      expression: "showActions"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }